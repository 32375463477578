module.exports = {
	env: "dev",
	server: {
		local: {
			page_url: 'http://localhost:8080',
		},
		dev: {
			page_url: 'https://simepox.monocilindrero.co/',
		},
		prod: {
			page_url: 'https://simepox.com',
		}
	},
}