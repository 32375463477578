import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/scss/custom-bootstrap.scss'

// Make BSidebar available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Import AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: 'ease-in-back', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});

//COMPONENTS
const Gallery = () => import ('COMPONENTS/Gallery.vue')
Vue.component('sp-gallery', Gallery)
const Modal = () => import ('COMPONENTS/Modal.vue')
Vue.component('sp-modal', Modal)
const Slider = () => import ('COMPONENTS/Slider.vue')
Vue.component('sp-slider', Slider)
const SliderContent = () => import ('COMPONENTS/SliderContent.vue')
Vue.component('sp-slider-content', SliderContent)

//TEMPLATES
const Normal = () => import ('./templates/normal')
Vue.component('normal', Normal)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
